import { Control, Controller, FieldValues } from 'react-hook-form'
import { Permission } from '@src/types/account'
import { CardCheckbox } from '../CardCheckbox'
import { ModalPermissionsBox } from './style'

type ModalPermissionsProps = {
  permissions: Permission[]
  control: Control<FieldValues, any>
}

const ModalPermissions = ({ permissions, control }: ModalPermissionsProps) => {
  function handlePermission(e: any, values: string[]) {
    const permission = e.target.value
    let newValues = [...values]
    if (newValues.includes(permission)) {
      newValues = []
    } else {
      newValues = [permission]
    }
    return newValues
  }

  return (
    <div>
      <h4 className="info-title">Control Center Access Level</h4>
      <Controller
        control={control}
        name="permissions"
        defaultValue={[]}
        rules={{
          required: 'Selecione pelo menos uma opção',
          validate: (options) => {
            return options.length >= 1 || 'Selecione pelo menos uma opção'
          },
        }}
        render={({ field }) => (
          <ModalPermissionsBox count={permissions?.length}>
            {permissions.map((permission, index) => (
              <CardCheckbox
                key={`check-${index}`}
                label={permission.name}
                value={permission.uuid}
                fullHeight
                checked={field.value?.includes(permission.uuid)}
                onChange={(e: any) => {
                  field.onChange(handlePermission(e, field.value ?? []))
                }}
              />
            ))}
          </ModalPermissionsBox>
        )}
      />
    </div>
  )
}

export default ModalPermissions
