/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  Input,
  Select,
  SelectedOptionsProps,
  Toggle,
  Upload
} from '@viswals/components'
import { Request, Institution, createInstitution } from '@viswals/services'
import useDatas from '@src/hooks/useDatas'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import { StyledContentInstitution } from './styles'

export default function FormCreateInstitution() {
  const navigate = useNavigate()

  const { countries } = useDatas()

  const [isError, setIsError] = useState<string>('')

  const { watch, setValue, register, handleSubmit, control } = useForm()

  async function onSubmit(data: any) {
    const dataToSend: any = {
      name: data.name,
      countryUuid: data.countryUuid,
      particular: data.particular,
      enabled: true,
      image: (data?.image?.file || '').split("base64,")?.[1],
      areas: (data?.area || []).map((uuid: string) => ({ uuid }))
    }

    try {
      setIsError('')
      await createInstitution(dataToSend as Request<Institution>)
      navigate('/administration/common/institution')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentInstitution className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Add new Institution"
            description="Here you can register on new institution"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <div className="content-icon-upload">
            <Upload
              label="Image"
              placeholder="Upload Image"
              formRegister={{ ...register('image'), watch, setValue }}
            />
            <div className="box-preview">
              <span className="box-preview-title">Preview</span>
              <div className="box-preview-image">
                <img src={watch('image.file')} alt="" />
              </div>
            </div>
          </div>

          <Select
            label="Country"
            labelTitle="Countries"
            options={
              countries.map((value) => ({
                label: value.name,
                value: value.uuid,
                icon: value.icon,
              })) as SelectedOptionsProps[]
            }
            formRegister={{ ...register('countryUuid'), watch }}
          />

          <div style={{ height: 20 }} />

          <Select
            isMulti={true}
            label="Area"
            labelTitle="Select Area"
            placeholder="Select area"
            options={[
              {
                label: 'Medicine',
                value: '84ce4d70-a442-412b-bf27-06f4544a8661',
                icon: '',
                isoCode: '',
              },
              {
                label: 'Dentistry',
                value: '4beed17b-a38a-4da1-8b26-94d2f1513001',
                icon: '',
                isoCode: '',
              },
              {
                label: 'Psychology',
                value: 'e117dcf1-4acd-499f-80d2-7c868f23d6d0',
                icon: '',
                isoCode: '',
              }
            ]}
            formRegister={{ ...register('area'), watch }}
          />

          <div className="content-toggle-switch">
            <Toggle isBig name="particular" control={control} />
            <div className="box-preview">Private</div>
          </div>

          <Button label="Create" type="submit" />

          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/institution')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentInstitution>
  )
}
