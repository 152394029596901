import styled, { css } from 'styled-components'
import { OpenTypeProps } from './alert'

export const ModalBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c1c1e90;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContentBox = styled.div`
  width: 126rem;
  height: 48.7rem;
  background-color: #1c1c1e;
  border: 1px solid #7c7c8025;
  border-radius: 1.5rem;
  padding: 4rem;

  -webkit-box-shadow: 0px 1rem 2rem 0px #00000080;
  -moz-box-shadow: 0px 1rem 2rem 0px #00000080;
  box-shadow: 0px 1rem 2rem 0px #00000080;
`

type ModalHeaderBoxProps = {
  openType?: OpenTypeProps | null;
  count?: number;
}

export const ModalHeaderBox = styled.div<ModalHeaderBoxProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-family: 'Poppins';
    font-size: 1.2rem;
    font-weight: bold;
    color: #5bdf6f;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;

    .action-reset,
    .hover-reset:hover {
      svg {
        color: #ee66ff;
      }
    }
    .action-suspend,
    .hover-suspend:hover {
      svg {
        color: #ffb23b;
      }
    }
    .action-delete,
    .hover-delete:hover {
      svg {
        color: #f72a1e;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    list-style: none;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    svg {
      width: 2.4rem;
      color: #8e8e93;
    }

    &.suspend {
      svg {
        color: #ffb23b;
      }
    }

    &:disabled {
      svg {
        opacity: 0.15;
      }
    }
  }
`

export const ModalBodyBox = styled.div`
  position: relative;
  margin-top: 4rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 4rem;

  .info {
    flex: 1;
  }

  .info-title {
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: bold;
    color: #7c7c80;
    margin-bottom: 1.6rem;
  }
`
type ModalPhotoBoxProps = {
  color: string
  hasPhoto?: boolean
}

export const ModalPhotoBox = styled.div<ModalPhotoBoxProps>`
  padding: 2rem;
  width: 100%;
  height: 24rem;
  background-color: #222224;
  border-radius: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #2c2c2e;
  -webkit-box-shadow: 0px 2px 2px 0px #00000025;
  -moz-box-shadow: 0px 2px 2px 0px #00000025;
  box-shadow: 0px 2px 2px 0px #00000025;

  .circle-box {
    width: 20rem;
    height: 20rem;

    .line {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      border-radius: 100%;

      &.b1 {
        border: 1px solid ${({ color }) => color}10;
      }
      &.b2 {
        border: 1px solid ${({ color }) => color}15;
      }
      &.b3 {
        border: 1px solid ${({ color }) => color}20;
      }
      &.b4 {
        border: 1px solid ${({ color }) => color}25;
      }
    }

    .containerInfo {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #3a3a3c;
      border-radius: 100%;
      border: 0.5rem solid
        ${({ hasPhoto, color }) => (hasPhoto ? color : '#8e8e93')};

      img {
        max-width: 100%;
      }
    }
  }
`

export const ModalInformationBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const CardInput = styled.div`
  width: 100%;
  height: 4rem;

  background-color: #222224;

  border-radius: 0.5rem;

  -webkit-box-shadow: 0px 1px 1px 0px #00000025;
  -moz-box-shadow: 0px 1px 0px 0px #00000025;
  box-shadow: 0px 1px 0px 0px #00000025;
  padding: 1rem;

  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #aeaeb2;
  &.disabled {
    color: #545456;
  }

  outline: 1px solid #2c2c2e;
  &:focus-within {
    outline: 2px solid #5bdf6f;
  }
  label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  input {
    font-family: 'Oxanium';
    font-size: 1rem;
    font-weight: normal;
    border: none;
    flex: 1;
    background-color: transparent;
    text-align: right;
    color: #aeaeb2;
    outline: none;
    caret-color: #5bdf6f;
    height: 100%;
  }
`

export const ModalPermissionsBox = styled.div<ModalHeaderBoxProps>`
  display: grid;
  grid-template-columns: repeat(${({ count }) => count || 4}, 1fr);
  grid-gap: 1rem;
  width: 100%;
  height: 24rem;
`

export const ButtonSend = styled.button`
  width: 100%;
  height: 3.2rem;
  margin-top: 4rem;
  background-color: #5bdf6f;
  border-radius: 0.5rem;
  border: none;

  font-family: 'Nebula';
  font-size: 1rem;
  font-weight: bold;
  color: #121212;
  &:disabled {
    opacity: 0.15;
  }
`
export type ModalAlertBoxProps = {
  openType: OpenTypeProps | null
}

export const ModalAlertBox = styled.div<ModalAlertBoxProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c1c1e90;
  z-index: 99;

  .vw-alert-modal {
    background-color: #1c1c1e90;
    .vw-alert-modal {
      background-color: #222224;
      min-width: 57.6rem;
      -webkit-box-shadow: 0px 10px 6px 0px #00000025;
      -moz-box-shadow: 0px 10px 6px 0px #00000025;
      box-shadow: 0px 10px 6px 0px #00000025;

      .vw-alert-modal-content-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          max-width: 15rem;
        }
      }

      .vw-alert-modal-button-confirm {
        ${(props) => {
    if (props.openType === 'ACTIVE') {
      return css`
              background-color: #15d7e5;
              color: #121212;
            `
    }

    if (props.openType === 'DELETE') {
      return css`
              background-color: #f72a1e;
            `
    }
    if (props.openType === 'SUSPEND') {
      return css`
              background-color: #ffb23b;
              color: #121212;
            `
    }
    return css`
            background-color: #ee66ff;
          `
  }}
      }
    }
  }
`
export const InviteTokenBox = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  h4 {
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: bold;
    color: #7c7c80;
  }

  .box-code {
    margin-top: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
    width: 100%;
    height: 24rem;
    background-color: #222224;
    border: 1px solid #2c2c2e;
    -webkit-box-shadow: 0px 2px 2px 0px #00000025;
    -moz-box-shadow: 0px 2px 2px 0px #00000025;
    box-shadow: 0px 2px 2px 0px #00000025;

    h5 {
      font-family: 'Oxanium';
      font-size: 0.9rem;
      font-weight: normal;
      color: #aeaeb2;
      text-transform: uppercase;
    }
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      gap: 2rem;
      li {
        height: 6.4rem;
        width: 6.4rem;
        border-radius: 0.7rem;
        background-color: #151517;
        border: 1px solid #2c2c2e;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Poppins';
        font-size: 2.9rem;
        font-weight: bold;
        color: #aeaeb2;
      }
    }
  }
  .btn-action {
    display: flex;
    justify-content: flex-end;
    button {
      width: 36.7rem;
    }
  }
`
