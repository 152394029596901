import Layout from '@src/components/Layout'
import { AccountGroup, Permission } from '@src/types/account'
import { useEffect, useState } from 'react'
import api from '@src/services/api'
import { ContainerUpperCalendarChildren } from '../GlobalAccounts/styles'
import { SuperAdministratorBox } from './style'
import SuperAdministratorHeaderMenu from './components/HeaderMenu'
import SuperAdministratorGroupMenu from './components/GroupMenu'
import SuperAdministratorContent from './components/Content'

const SuperAdministratorPage = () => {
  const [groups, setGroups] = useState<AccountGroup[]>([])
  const [permissions, setPermissions] = useState<Permission[]>([])
  useEffect(() => {
    getGroups()
    getListPermissions()
  }, [])

  async function getGroups() {
    const req = { fields: ['uuid', 'name', 'icon', 'slug'] }
    const { data } = await api.post(`/cc-account/account-group/retrieve`, req)
    setGroups(data?.data)
  }

  async function getListPermissions() {
    const req = { fields: ['uuid', 'name'] }
    const { data } = await api.post(`/cc-account/permission/retrieve`, req)
    setPermissions(data?.data?.splice(0, 4))
  }

  return (
    <Layout
      upperBarChilden={
        <ContainerUpperCalendarChildren>
          <div className="leftBox">
            <span>cc super administrator panel</span>
          </div>
        </ContainerUpperCalendarChildren>
      }
    >
      <SuperAdministratorBox>
        <div className="admCard">
          <SuperAdministratorHeaderMenu />
        </div>
        <div className="admCard">
          {groups?.length && <SuperAdministratorGroupMenu groups={groups} />}
        </div>
        <div className="admCard">
          {groups?.length && (
            <SuperAdministratorContent
              permissions={permissions}
              groups={groups}
            />
          )}
        </div>
      </SuperAdministratorBox>
    </Layout>
  )
}

export default SuperAdministratorPage
