import { FieldValues, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import Loading from '@src/components/Loading'
import api from '@src/services/api'
import {
  Account,
  AccountGroup,
  AccountStatusEnum,
  Permission,
} from '@src/types/account'
import ModalHeader from './header'
import ModalInformation from './information'
import ModalPermissions from './permissions'
import ModalPhoto from './photo'
import { ButtonSend, ModalBodyBox, ModalBox, ModalContentBox } from './style'
import useLogic from '../../useLogic'
import ModalAlert, { OpenTypeProps } from './alert'
import InviteToken from './inviteToken'

export type SuperAdministratorModalProps = {
  open: Account
  groups: AccountGroup[]
  permissions: Permission[]
  onClose: () => void
  onCreate: () => void
  onChangeColumn: (key: string, value: any) => void
  onRemove: (uuid: string) => void
}

const SuperAdministratorModal = ({
  open,
  groups,
  permissions,
  onClose,
  onCreate,
  onChangeColumn,
  onRemove,
}: SuperAdministratorModalProps) => {
  const { getGroupUuid } = useLogic()
  const [loading, setLoading] = useState(false)
  const [openType, setOpenType] = useState<OpenTypeProps | null>(null)
  const [code, setCode] = useState<string | null>(null)

  const {
    formState: { isValid },
    control,
    watch,
    reset,
    register,
    handleSubmit,
  } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: {},
  })

  useEffect(() => {
    if (open?.uuid) {
      reset(open)
    } else {
      reset({})
    }
  }, [open])

  if (!open) {
    return <div />
  }

  async function onSubmit(form: FieldValues) {
    if (open?.uuid) {
      onSubmitUpdate(form)
    } else {
      onSubmitCreate(form)
    }
  }

  async function getCode(uuid: string) {
    const req = {
      fields: ['uuid', 'inviteToken'],
      filters: {
        uuid,
      },
    }
    const { data } = await api.post(`/cc-account/account/retrieve`, req)
    console.log({ data })
    setCode(data?.data[0]?.inviteToken ?? 'ABCDEFGH')
  }

  async function onSubmitCreate(form: FieldValues) {
    const req = {
      username: form.username,
      mobileNumber: form.mobileNumber,
      permissions: form.permissions,
      accountGroup: getGroupUuid(groups),
    }
    setLoading(true)
    const { data } = await api.post(`/cc-account/account/create`, req)
    await getCode(data?.data?.uuid)
    setLoading(false)
  }
  async function onSubmitUpdate(form: FieldValues) {
    const req = {
      uuid: form.uuid,
      permissions: form.permissions,
    }
    setLoading(true)
    await api.post(`/cc-account/account/update`, req)
    setLoading(false)
    onChangeColumn('permissions', req.permissions)
  }

  async function deleteAccount() {
    const req = {
      uuid: open?.uuid,
      username: open?.username,
    }
    await api.post(`/cc-account/account/delete`, req)
    onRemove(open?.uuid as string)
  }

  async function resetPassword() {
    const { data } = await api.get(
      `/cc-account/auth/reset-password/${open?.uuid}`
    )
    setCode(data?.password)
  }

  async function handleAction(action: OpenTypeProps | null) {
    setOpenType(null)
    if (action === 'DELETE') {
      setLoading(true)
      await deleteAccount()
      setLoading(false)
    } else if (action === 'RESET-PASSWORD') {
      setLoading(true)
      await resetPassword()
      setLoading(false)
    } else if (!!action && ['SUSPEND', 'ACTIVE'].includes(action)) {
      const status = action as AccountStatusEnum
      const req: Account = {
        uuid: open?.uuid,
        status,
      }
      setLoading(true)
      await api.post(`/cc-account/account/update`, req)
      setLoading(false)
      onChangeColumn('status', status)
    }
  }

  function inviteTokenCreated() {
    setCode(null)
    if (open?.uuid) {
      onClose()
    } else {
      onCreate()
    }
  }

  return (
    <ModalBox>
      <ModalAlert
        openType={openType}
        handleAction={(action) => handleAction(action)}
      />
      <ModalContentBox>
        <ModalHeader
          openType={openType}
          account={open}
          handleAction={(action) => setOpenType(action)}
          title={
            open?.uuid ? 'Edit CC Professional' : 'Add Viswals CC Professional'
          }
          onClose={onClose}
        />
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <ModalBodyBox>
            {loading ? (
              <Loading />
            ) : (
              <>
                {code ? (
                  <InviteToken
                    code={code}
                    onClose={() => inviteTokenCreated()}
                  />
                ) : (
                  <>
                    <ModalPhoto photo={open?.photo} />
                    <ModalInformation account={open} register={register} />
                    <div className="info">
                      <ModalPermissions
                        permissions={permissions}
                        control={control}
                      />
                      <ButtonSend
                        type="submit"
                        disabled={!isValid || (!!open?.uuid && open?.permissions?.join('-') === watch('permissions').join('-'))}
                      >
                        {open?.uuid ? 'SaVE CHaNGES' : 'add professional'}
                      </ButtonSend>
                    </div>
                  </>
                )}
              </>
            )}
          </ModalBodyBox>
        </form>
      </ModalContentBox>
    </ModalBox>
  )
}

export default SuperAdministratorModal
