import styled from 'styled-components'

interface CardCheckboxProps {
  fullHeight?: boolean;
}

export const CheckboxLabel = styled.label<CardCheckboxProps>`
  position: relative;
  width: 100%;
  height: ${({ fullHeight }) => fullHeight ? '100%' : '4rem'};
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #222224;
  border: 1px solid #2c2c2e;
  border-radius: 0.5rem;

  -webkit-box-shadow: 0px 1px 1px 0px #00000025;
  -moz-box-shadow: 0px 1px 0px 0px #00000025;
  box-shadow: 0px 1px 0px 0px #00000025;
  padding: 1rem;

  font-family: 'Oxanium';
  font-size: 1rem;
  font-weight: normal;

  color: #aeaeb2;
  cursor: pointer;

  &.disabled {
    color: #545456;
  }

  &.checked {
    background-color: #5bdf6f25;
    border-color: #80db7c50;
    color: #80db7c;
  }
  input {
    position: absolute;
    display: none;
  }
`
