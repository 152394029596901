import { CheckboxLabel } from './style'

type CardCheckboxProps = {
  label: string
  value: string
  checked: boolean
  onChange: any
  fullHeight?: boolean;
}

export const CardCheckbox = ({
  label,
  value,
  checked,
  onChange,
  fullHeight
}: CardCheckboxProps) => {
  return (
    <CheckboxLabel fullHeight={fullHeight} className={checked ? 'checked' : ''}>
      {label}
      <input type="checkbox" value={value} onChange={onChange} />
    </CheckboxLabel>
  )
}
