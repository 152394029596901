import iconUserAdministrator from '@images/userAdministrator.svg'
import { TablePhotoBox } from './style'

type TablePhotoProps = {
  photo?: string
  styles?: any
}

const TablePhoto = ({ photo, styles }: TablePhotoProps) => {
  const hasPhoto = !!photo

  return (
    <TablePhotoBox hasPhoto={hasPhoto}>
      <div className="containerInfo">
        <figure>
          <img
            style={styles}
            src={
              photo ? `data:image/png;base64,${photo}` : iconUserAdministrator
            }
            alt="User Administrator"
          />
        </figure>
      </div>
    </TablePhotoBox>
  )
}

export default TablePhoto
