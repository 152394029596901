import { Icon } from '@viswals/components'
import { Account } from '@src/types/account'
import { ModalHeaderBox } from './style'
import { OpenTypeProps } from './alert'

type ModalHeaderProps = {
  account?: Account
  title: string
  openType: OpenTypeProps | null
  onClose: () => void
  handleAction: (action: OpenTypeProps) => void
}

const ModalHeader = ({
  account,
  title,
  openType,
  onClose,
  handleAction,
}: ModalHeaderProps) => {
  const hasUser = !!account?.uuid

  return (
    <ModalHeaderBox openType={openType}>
      <div className="title">{title}</div>
      <div className="actions">
        <ul>
          {/* <li>
            <button
              type="button"
              className={`${
                openType === 'RESET-PASSWORD' ? 'action-reset' : 'hover-reset'
              }`}
              disabled={!hasUser}
              onClick={() => handleAction('RESET-PASSWORD')}
            >
              <Icon name="ResetPasswordUserCC" />
            </button>
          </li> */}
          <li>
            <button
              type="button"
              disabled={!hasUser}
              className={`${account?.status?.toLowerCase()} 
              ${openType === 'SUSPEND' ? 'action-suspend' : 'hover-suspend'}
              `}
              onClick={() =>
                handleAction(
                  account?.status === 'SUSPEND' ? 'ACTIVE' : 'SUSPEND'
                )
              }
            >
              <Icon name="InactiveActiveUserCC" />
            </button>
          </li>
          <li>
            <button
              type="button"
              className={`${openType === 'DELETE' ? 'action-delete' : 'hover-delete'
                }`}
              disabled={!hasUser}
              onClick={() => handleAction('DELETE')}
            >
              <Icon name="DeleteUserCC" />
            </button>
          </li>
        </ul>
        <button type="button" onClick={onClose}>
          <Icon name="Close8" />
        </button>
      </div>
    </ModalHeaderBox>
  )
}

export default ModalHeader
