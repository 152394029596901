import { useCallback, useEffect, useRef, useState } from 'react'
import useDiary from '@src/hooks/useDiary'
import Layout from '@src/components/Layout'
import moment, { DurationInputArg2, Moment } from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import axios from 'axios'
import { useDispatch } from 'react-redux'

import { LandingOnType } from '@viswals/services'
import { Icon, UserPhoto } from '@viswals/components'
import {
  ButtonGroup,
  ExpandedSearchBar,
  LinkGroup,
  LinkProps,
  WhoIsIn,
  UpperCalendar,
} from '@viswals/components-platform'

import useClinicianRegister from '@src/hooks/useClinicianRegister'
import {
  ButtonGroupWrapper,
  CurrentDate,
  LinkGroupWrapper,
  SearchWrapper,
} from '@src/components/Layout/UpperBar/style'
import {
  whoIsInTooltip,
  upperBarLandingOn,
} from '@src/components/Layout/UpperBar/upperBarData'
import { setActionCustomizeDiary } from '@src/store/reducers/diary/actions'

import useLayout from '@src/hooks/useLayout'
import GoogleMap from './Maps'
import {
  AgentSearchContainer,
  AgentSearchField,
  AgentSearchFieldInput,
  AgentTablBody,
  AgentTablBodyColCode,
  AgentTablBodyColName,
  AgentTablBodyRow,
  AgentTable,
  AgentTableHead,
  AgentTableHeadColCode,
  AgentTableHeadColName,
  AgentTableHeadRow,
  CalendarAction,
  CalenderUpperWrapper,
  Container,
  ContainerUpperCalendarChildren,
  MapArea,
  ProfileIcon,
  TBProfileIconContainer,
  TBProfileIconSection,
  TopLabel,
  UserSearchField,
} from './style'

import GlobalViewTotal from './components/GlobalViewTotals'
import UpperCalender from './components/upper-calender'
import PreRegistrationList from './components/list-view'
import Images from '@src/assets/images'
import Config from '@src/services/api/config'

const mockAgentData = [
  {
    "referral_code": "MU24",
    "agent_name": "Mariana Urbano"
  },
  {
    "referral_code": "NC24",
    "agent_name": "Nuno Couto"
  },
  {
    "referral_code": "MG24",
    "agent_name": "Mónica Gomes"
  },
  {
    "referral_code": "AM24",
    "agent_name": "Abel Moreira"
  },
  {
    "referral_code": "LR24",
    "agent_name": "Lucas Rocha"
  },
  {
    "referral_code": "FS24",
    "agent_name": "Filipe Seabra"
  }
]

const options: LinkProps[] = [
  {
    value: '/global-view/pre-registration',
    title: 'Global View',
    icon: 'GlobalView'
  },
  {
    value: '/global-view/pre-registration-list',
    title: 'Registration List',
    icon: 'Roadmap'
  },
]

const dateStringFormate = 'YYYY-MM-DD'

const PreRegistrationView = () => {

  const [isGlobalView, setGlobalView] = useState(true)
  const [selectedDate, setSelectedDate] = useState(moment().format(dateStringFormate))
  const [openSearchAgent, setOpenSearchAgent] = useState(false)
  const [agentData, setAgentData] = useState<any>([])
  const [agentFilterData, setAgentFilterData] = useState<any>([])
  const [selectedAgentData, setSelectedAgentData] = useState<any>(null)
  const [searchAgent, setSearchAgent] = useState<any>('')
  const [searchUser, setSearchUser] = useState<any>('')

  const navigate = useNavigate()
  const location = useLocation()
  const { layout, setLayout } = useLayout()
  const { diary, updateLandingOn, updateUpperCalendarSelect } = useDiary()
  const { clinician } = useClinicianRegister()
  const diaryRef = useRef<any>()
  const dispatch = useDispatch()

  useEffect(() => {
    getAgentData()
  }, [])

  useEffect(() => {
    dispatch(setActionCustomizeDiary({ ...diaryRef.current, selectedAgentData }))
  }, [selectedAgentData])

  useEffect(() => {
    diaryRef.current = diary
  }, [diary])

  useEffect(() => {
    if (isGlobalView) {
      setOpenSearchAgent(false)
    }
  }, [isGlobalView])

  useEffect(() => {
    const { startData, endDate } = calculateStartEndDate(diaryRef.current?.landingOn)
    updateUpperCalendarSelect(startData, endDate)
  }, [selectedDate, diary.landingOn])

  const getAgentData = async () => {
    try {
      const { status, data } = await axios.get(`${Config.REACT_APP_GATEWAY_URL}/pre-register/v2/referral_codes`, { headers: { 'Authorization': "Bearer 21c24623-0daa-452a-a7d4-156b5702a962" } })
      if (status === 200 && data?.data) {
        setAgentData([...data.data])
        setAgentFilterData([...data.data])
      } else {
        setAgentData([...mockAgentData])
        setAgentFilterData([...mockAgentData])
      }
    } catch (error) {
      setAgentData([...mockAgentData])
      setAgentFilterData([...mockAgentData])
    }
  }

  const calculateStartEndDate = (landingOn: DurationInputArg2) => {
    const calType: DurationInputArg2 = (landingOn && `${landingOn}` !== 'AS_LEFT' ? landingOn : 'WEEK').toLowerCase() as DurationInputArg2
    const startData: string = calType === 'year' ? `${moment(selectedDate, dateStringFormate).format("YYYY")}-${parseInt(moment(selectedDate, dateStringFormate).format("MM")) < 7 ? '01' : '07'}-01` : moment(selectedDate, dateStringFormate).startOf(calType).format(dateStringFormate)
    const endDate: string = moment(startData, dateStringFormate).add((calType === 'year' ? 6 : 1), (calType === 'year' ? "month" : calType)).add(-1, 'day').format(dateStringFormate)
    return { startData, endDate }
  }

  const checkDateIsBeforeDate = (date: Moment) => {
    return moment().add(24, 'month').diff(date, 'day') >= -3
  }

  const onClickUpperCalTypeBtn = (value: string) => {
    const { startData, endDate } = calculateStartEndDate(value as DurationInputArg2)
    updateLandingOn(value as LandingOnType, startData, endDate)
    if (value === 'AS_LEFT') {
      setLayout('openUpperCalendar', false)
    }
  }

  const onClickDateUC = (date: string) => {
    if (checkDateIsBeforeDate(moment(date, dateStringFormate))) {
      setSelectedDate(date);
    }
  }

  const onClickMonthUC = (monthNum: number) => {
    let updatedDate = moment(selectedDate, dateStringFormate).set("month", monthNum)
    if (checkDateIsBeforeDate(updatedDate)) {
      setSelectedDate(updatedDate.format(dateStringFormate));
    }
  }

  const onChangeAgentSearch = (e: any) => {
    setSearchAgent(e.target.value)
    if (!e.target.value) {
      setAgentFilterData([...agentData])
    } else {
      const searchLower = (e.target.value || '').toLowerCase()
      const filteredData = agentData.filter((ele: any) => (ele.referral_code).toLowerCase().includes(searchLower) || (ele.agent_name).toLowerCase().includes(searchLower))
      setAgentFilterData([...filteredData])
    }
  }

  const onChangeUserSearch = (e: any) => {
    setSearchUser(e.target.value)
  }

  const openUpperCalendar = layout.openUpperCalendar ?? false

  return (
    <Layout
      upperBarChilden={
        <ContainerUpperCalendarChildren>
          <div className="leftBox">
            <CurrentDate>{moment().format('D MMMM YYYY')}</CurrentDate>

            <LinkGroupWrapper>
              <LinkGroup
                linkActive={isGlobalView ? "/global-view/pre-registration" : "/global-view/pre-registration-list"}
                options={options}
                styleButton="CC_BUTTON"
                handleClick={(value: any) => setGlobalView(value.includes('list') ? false : true)}
              />
            </LinkGroupWrapper>
          </div>

          <div className="rightBox">

            {!isGlobalView &&
              <UserSearchField>
                <Images.Search />
                <AgentSearchFieldInput
                  placeholder='Search User by name or Code'
                  value={searchUser}
                  onChange={onChangeUserSearch}
                />
              </UserSearchField>
            }

            <Popover
              isOpen={openSearchAgent}
              positions={['bottom']}
              onClickOutside={() => setOpenSearchAgent(false)}
              content={() => {
                return (
                  <AgentSearchContainer>
                    <AgentSearchField>
                      <Images.Search />
                      <AgentSearchFieldInput
                        placeholder='Search PR Agent by name or Code'
                        value={searchAgent}
                        onChange={onChangeAgentSearch}
                      />
                    </AgentSearchField>
                    <AgentTable>
                      <AgentTableHead>
                        <AgentTableHeadRow>
                          <AgentTableHeadColCode>Code</AgentTableHeadColCode>
                          <AgentTableHeadColName>Name</AgentTableHeadColName>
                        </AgentTableHeadRow>
                      </AgentTableHead>
                      <AgentTablBody>
                        {agentFilterData.map((item: any) => {
                          return (
                            <AgentTablBodyRow active={item.referral_code === selectedAgentData?.referral_code} onClick={() => {
                              if (item.referral_code === selectedAgentData?.referral_code) {
                                setSelectedAgentData(null)
                              } else {
                                setOpenSearchAgent(false)
                                setSelectedAgentData(item)
                              }
                            }} >
                              <AgentTablBodyColCode>{item.referral_code}</AgentTablBodyColCode>
                              <AgentTablBodyColName>{item.agent_name}</AgentTablBodyColName>
                              {item.referral_code === selectedAgentData?.referral_code && <Images.AgentSelected />}
                            </AgentTablBodyRow>
                          )
                        })}
                      </AgentTablBody>
                    </AgentTable>
                  </AgentSearchContainer>
                )
              }}
            >
              <CalendarAction
                agent={true}
                onClick={() => setOpenSearchAgent(!openSearchAgent)}
                aria-hidden="true"
                className={openSearchAgent ? 'active' : ''}
              >
                <Images.AgentSearch color={!openSearchAgent && 'rgb(174, 174, 178)'} />
              </CalendarAction>
            </Popover>
            <CalendarAction
              onClick={() => setLayout('openUpperCalendar', !openUpperCalendar)}
              aria-hidden="true"
              className={openUpperCalendar ? 'active' : ''}
            >
              <Icon name="CalendarCcHeader" />
            </CalendarAction>
            <ButtonGroupWrapper>
              {upperBarLandingOn && (
                <ButtonGroup
                  isActive={diary?.landingOn}
                  options={upperBarLandingOn}
                  handleClick={(value) => onClickUpperCalTypeBtn(value)}
                />
              )}
            </ButtonGroupWrapper>

            <CalendarAction
              onClick={() => navigate('/global-view')}
              aria-hidden="true"
              style={{ minWidth: '156px', marginLeft: '53px', marginRight: '24px', background: 'rgb(50, 215, 75, 0.20)' }}
              className={'active'}
            >
              <Icon name="GlobalView" />
              <span>Worldwide Pre-Registration</span>
            </CalendarAction>

            <TBProfileIconContainer focus={true}>
              <TBProfileIconSection focus={true}>
                <ProfileIcon src={'https://i.pravatar.cc/300?img=5'} />
              </TBProfileIconSection>
            </TBProfileIconContainer>

          </div>
        </ContainerUpperCalendarChildren>
      }
    >
      <Container>
        {openUpperCalendar && (
          <CalenderUpperWrapper show={openUpperCalendar}>
            <UpperCalender
              type={(diary?.landingOn && diary.landingOn !== 'AS_LEFT' ? diary.landingOn : 'WEEK').toLowerCase()}
              selectedDate={selectedDate}
              onClickDate={onClickDateUC}
              onClickMonth={onClickMonthUC}
            />
          </CalenderUpperWrapper>
        )}

        {isGlobalView &&
          <MapArea>
            <TopLabel>
              <p>
                <span>Global Live </span> Stats
              </p>
            </TopLabel>

            <GoogleMap />
          </MapArea>
        }

        {isGlobalView && <GlobalViewTotal />}

        {!isGlobalView && <PreRegistrationList selectedAgentData={selectedAgentData} openUpperCalendar={openUpperCalendar} userSearch={searchUser} />}

      </Container>
    </Layout>
  )
}

export default PreRegistrationView
