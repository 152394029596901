import { SxProps } from '@mui/material'

const gridStyle: SxProps<any> | undefined = {
  width: '100%',
  borderColor: '#333',
  cursor: 'pointer',
  '.MuiSvgIcon-root': {
    color: 'white',
  },
  '.MuiDataGrid-row.Mui-selected': {
    backgroundColor: '#2b2b2b',
    '&:hover': {
      backgroundColor: '#2b2b2b',
    },
  },
  '.MuiDataGrid-row': {
    '&:hover': {
      backgroundColor: '#2b2b2b',
    },
  },
  '.MuiDataGrid-columnHeaderTitle': {
    color: 'white',
    fontSize: 11,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
  },
  '.MuiDataGrid-columnHeaders': {
    borderColor: '#383840',
    color: 'white',
    backgroundColor: '#383840',
  },
  '.MuiDataGrid-cell': {
    border: '1px solid #333',
    color: 'white',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
  '.MuiDataGrid-footerContainer': {
    borderColor: 'transparent',
  },
  '.MuiTablePagination-actions': {
    color: 'white',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
  '.Mui-disabled': {
    color: '#AEAEB2',
  },
  '.MuiTablePagination-displayedRows': {
    color: 'white',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
  '.MuiTablePagination-selectLabel': {
    color: 'white',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
  '.MuiDataGrid-selectedRowCount': {
    color: 'white',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
  '.MuiSelect-select': {
    color: 'white',
    fontSize: 12,
    lineHeight: '12px',
    fontFamily: 'Poppins',
    paddingTop: '10px',
  },
  '.MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '.MuiDataGrid-cellContent': {
    color: 'white',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
  '.MuiDataGrid-cell:focus-within': {
    outline: 'transparent',
  },
}

export default gridStyle
