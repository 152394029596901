import { useEffect, useState } from 'react'
import { Account, AccountGroup, Permission } from '@src/types/account'
import { useParams } from 'react-router-dom'
import api from '@src/services/api'
import SuperAdministratorModal from './ Modal'
import SuperAdministratorTable from './Table'
import { SuperAdministratorContentBox } from './style'
import useLogic from '../useLogic'

type SuperAdministratorContentProps = {
  groups: AccountGroup[]
  permissions: Permission[]
}

const SuperAdministratorContent = ({
  groups,
  permissions,
}: SuperAdministratorContentProps) => {
  const { getGroupUuid } = useLogic()
  const params = useParams()

  const [open, setOpen] = useState<any>(null)
  const [accounts, setAccounts] = useState<Account[]>([])

  useEffect(() => {
    getAccounts()
  }, [params?.slug])

  function handleOpen(row: any) {
    setOpen(row)
  }

  function onCreate() {
    setOpen(null)
    getAccounts()
  }

  async function getAccounts() {
    const req = {
      fields: [
        'uuid',
        'username',
        'firstName',
        'lastName',
        'nationality',
        'dateOfBirth',
        'mobileNumber',
        'photo_url',
        'number',
        'status',
        'inviteToken',
        'permissions',
        'accountGroup',
        'createdAt',
      ],
      filters: {
        accountGroup: getGroupUuid(groups),
      },
    }
    const { data } = await api.post(`/cc-account/account/retrieve`, req)
    setAccounts(data?.data)
  }

  function onChangeColumn(key: string, value: any) {
    const uuid = open?.uuid
    setAccounts((list) => [
      ...list.map((l) => {
        if (l.uuid === uuid) {
          return {
            ...l,
            [key]: value,
          }
        }
        return l
      }),
    ])
    setOpen(null)
  }

  function onRemove(uuid: string) {
    setAccounts((list) => [...list.filter((f) => f.uuid !== uuid)])
    setOpen(null)
  }

  return (
    <SuperAdministratorContentBox>
      {!!open && (
        <SuperAdministratorModal
          groups={groups}
          permissions={permissions}
          open={open}
          onClose={() => setOpen(null)}
          onCreate={() => onCreate()}
          onChangeColumn={(key, value) => onChangeColumn(key, value)}
          onRemove={(uuid) => onRemove(uuid)}
        />
      )}
      <div className="header-content">
        <div>
          <h3 className="title">Our Team</h3>
          <h4 className="subTitle">Global Verification Agents</h4>
        </div>
        <div className="button-action">
          <button type="button" onClick={() => setOpen({})}>
            ADD CC PROFESSIONAL
          </button>
        </div>
      </div>

      <SuperAdministratorTable
        accounts={accounts}
        handleOpen={(row) => handleOpen(row)}
      />
    </SuperAdministratorContentBox>
  )
}

export default SuperAdministratorContent
